// V3 styled components collection
import styled from 'styled-components';
import {
  contentWidth,
  Button as ButtonV2,
  colors as colorsV2,
  mobileThreshold,
  mobileThresholdPixels,
  Container as ContainerV2,
  FormInput,
  margins,
  menuBarHeightPixels, menuBarHeightWithBannerPixels,
  mobileMenuBarHeightPixels, mobileMenuBarHeightWithBannerPixels,
  Title1,
  Row,
} from '../v2/styledComponents';

export const fontSizes = {
  xs: '12px',
  s: '15px',
  m: '18px',
  l: '24px',
  xl: '30px',
  x2l: '36px',
  x3l: '46px',
  x4l: '50px',
};

export const colors = {
  ...colorsV2,
  black: '#000',
  blueGrey: '#DFE4EF',
  darkBlueGrey: '#7F8FA4',
  lightGrey2: '#E5E5E5',
  lightGrey3: '#C4C4C4',
  lightGrey4: 'rgba(23, 44, 66, 0.4)',
  lightGrey5: '#FBFBFB',
  lightGrey6: '#BBBBBB',
  red: '#FF0000',
  popup: 'rgba(23, 44, 66, 0.72)',
  opacityFilter: 'rgba(0, 0, 0, 0.25)',
  myAccountMenu: 'rgba(0, 0, 0, 0.1)',
  shadowGiftBanner: 'rgba(0, 0, 0, 0.05)',
  whiteTransparent: 'rgba(255,255,255,0.95)',
  navyTransparentLight: 'rgba(23,44,66,0.20)',
  lightBlue2: '#F5F8FF',
  mediumGrey: '#F0F2F4',
  lightBlue3: '#FCFDFF',
  yellow2: '#EEC114',
  lightPink: '#E6CFC5',
  blue: '#638BB9',
  darkGrey2: '#7E7E7E',
  shadow: 'rgba(223, 228, 239, 0.5)',
  green: '#B7C7C4',
  greenShadow: 'rgba(183, 199, 196, 0.3)',
  error: '#FF3A44',
};

export const Title2 = styled.h2`
  font-family: Libre Baskerville;
  font-weight: 700;
  line-height: 30px;
  font-size: 32px;
  text-align: center;
  color: ${colors.navy};
  margin: 0px 0px ${({ marginBottom }) => marginBottom || 20}px;
  white-space: pre-wrap;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 220px;
    font-size: 20px;
    margin: 0px 15px ${({ marginBottom }) => marginBottom ? 40 : 0}px;
  }
`;

export const Title3 = styled.h2`
  font-family: Libre Baskerville;
  font-weight: 700;
  line-height: 30px;
  font-size: 26px;
  letter-spacing: 2px;
  color: ${colors.navy};
  margin: 0px 0px ${({ marginBottom }) => marginBottom ? 122 : 5}px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.m};
    margin: 0px 0px ${({ marginBottom }) => marginBottom ? 40 : 0}px;
    line-height: 23px;
  }
`;

export const Title4 = styled.h3`
  color: ${({ theme }) => theme.colors.navy};
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 5.5px;
  margin-bottom: 15px;
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    font-size: 10px;
  }
`;

export const Subtitle2 = styled.span`
  font-family: Roboto;
  font-size: ${fontSizes.m};
  line-height: 23px;
  color: ${colors.navy};
  text-align: center;
  margin: -10px 0px 20px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    margin: 0px 0px 20px;
    line-height: 19px;
  }
`;

export const StyledTitle2 = styled(Title2)`
  font-size: 36px;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 26px;
  }
`;

export const Note = styled.p`
  font-family: Roboto;
  color: ${colors.navy};
  line-height: 20px;
  font-size: ${fontSizes.s};
  margin-top: 20px;
  ${({ center }) => center && 'text-align: center;'}

  @media (max-width: ${mobileThresholdPixels}) {
    text-align: center;
    font-size: ${fontSizes.xs};
    margin-top: 10px;
    margin-bottom: 25px;
  }
`;

export const Container = styled(ContainerV2)`
  && {
    background-color: ${colors.lightGrey};
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: ${({ hasNoBanner = true }) => hasNoBanner
    ? menuBarHeightPixels
    : menuBarHeightWithBannerPixels};

  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: ${({ hasNoBanner = true }) => hasNoBanner
    ? mobileMenuBarHeightPixels
    : mobileMenuBarHeightWithBannerPixels};
    width: 100vw;
  }
`;

export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 954px;
  padding: 15px;
  margin: 64px auto ${(props) => props.smallMarginBottom ? '80' : '120'}px;
  align-items: center;
  @media (max-width: ${mobileThresholdPixels}) {
    margin: 30px 0px 60px;
    ${(props) => props.smallMarginBottom && 'margin-bottom: 45px;'}
    max-width: 100vw;
    box-sizing: border-box;
  }
`;

export const ComponentsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Button = styled(ButtonV2)`
  text-transform: none;
  font-size: 22px;
  font-weight: 500;
  line-height: 24px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.s};
    min-height: 45px;
    min-width: 220px;
    padding: 12px;
  }
`;

export const UpTitle = styled.div`
  font-family: Roboto;
  font-weight: 500;
  font-size: 12px;
  line-height: 30px;
  margin-bottom: 16px;
  text-align: center;
  text-transform: uppercase;
  color: ${colors.navy};
  letter-spacing: 5.5px;
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    font-size: 10px;
    line-height: 15px;
    margin-bottom: 10px;
  }
`;

export const DashTextContainer = styled.div`
  display: flex;
  align-items: center;

  &:before {
    font-weight: lighter;
    font-size: 32px;
    color: ${colors.white};
    z-index: 0;
    content: '——';
    letter-spacing: -10px;
    top: -19px;
    margin-right: ${margins.s};
    position: relative;
    border-bottom: ${colors.navy} 1px solid;
  }
`;

export {
  contentWidth, mobileThreshold, mobileThresholdPixels, FormInput, margins,
  menuBarHeightPixels, menuBarHeightWithBannerPixels,
  mobileMenuBarHeightPixels, mobileMenuBarHeightWithBannerPixels, Title1, Row,
};
